(function (angular) {
    "use strict";

    angular.module("app")
        .run(checkToken);

    
    /**
     * Check access token.
     * If token is invalid or is expired, redirect to auth page
     */
    function checkToken(_appStorage, _vRoutes) {
        var token = _appStorage.get('token'),
            token_remember = _appStorage.get('token_remember');

        if (!token_remember) {
            var token_time = _appStorage.get('token_time'),
                expires_in = moment(token_time).add(2, 'hours'),
                token_expired = expires_in.isBefore(moment());
        }

        if (!token || token_expired) {
            location.href = _vRoutes.auth.login;
        }
    }
}(angular));